import $ from 'npm-zepto'

$(window).on('load', () => {
	$('body').addClass('loaded')
})

// import $ from 'npm-zepto';
// import { query, queryOne } from '@artcommacode/q';
//
// const links = query('a.year-link');
//
// const body = $('body');
//
// function loadYear(inputHref) {
// 	const href = `${inputHref}${window.location.search}`;
// 	console.log(href);
// 	body.empty();
// 	body.load(`${href} body`).then((response) => {
// 		console.log(response);
// 	});
// }
//
// for (const link of links) {
// 	console.log(link);
// 	link.addEventListener('click', (e) => {
// 		e.preventDefault();
// 		const href = link.getAttribute('href');
// 		loadYear(href);
// 	});
// }
